import React, { PropsWithChildren, useEffect, useMemo } from 'react';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Callout } from '@swyftx/aviary/atoms/Typography/Heading/Callout';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';
import { QuickBuy } from '@swyftx/aviary/icons/outlined';
import { Page, PageContent } from '@swyftx/aviary/layout/Page';

import { AppHeaderDashboardActions } from '@global-components/AppHeader/AppHeaderDashboardActions';
import PageBanner from '@global-components/PageBanner';

import { UIStore, UserStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { DashboardLayoutItem } from './types/Dashboard.types';
import { PriceAlertButton } from '../dynamic-price-alerts/components';
import { useProfileStatus } from '../verification/hooks/useProfileStatus';

type Props = {
  layout?: DashboardLayoutItem;
  className?: string;
  preview?: boolean;
  containerName: string;
};

const DashboardPage: React.FC<PropsWithChildren<Props>> = observer(
  ({ layout, className, preview, containerName, children }) => {
    const { userProfile, isUserOrMemberTradeEnabled } = UserStore.useUserStore;
    const { navigate } = useNavigateRoute();
    const { isFeatureEnabled } = useIsFeatureEnabled();

    const { atLeast } = useTailwindContainerBreakpoint({
      containerName,
    });

    const { setCustomHeaderOptions } = UIStore.useUIStore;
    const { isVerified, hasDeposited, hasTraded } = useProfileStatus();

    const completedVerification = useMemo(
      () => isVerified && hasDeposited && hasTraded,
      [hasDeposited, hasTraded, isVerified],
    );

    useEffect(() => {
      if (preview || !completedVerification) return;
      setCustomHeaderOptions(<AppHeaderDashboardActions hideCustomise />);

      return () => {
        if (preview || !completedVerification) return;

        setCustomHeaderOptions(undefined);
      };
    }, [completedVerification, preview, setCustomHeaderOptions]);

    const greeting = useMemo(() => {
      const date = DateTime.now();
      if (date.hour < 12) return 'morning';
      if (date.hour < 18) return 'afternoon';
      return 'evening';
    }, []);

    return (
      <Page
        showCompactHeader={false}
        data-spotlightelementid='dashboardPage-page'
        customHeader={
          <FlexLayout
            alignItems={atLeast.sm ? 'center' : 'start'}
            justifyContent='space-between'
            className='@xs:px-8 @xs:py-16 @sm:p-0'
          >
            <FlexLayout
              alignItems={atLeast.sm ? 'center' : 'start'}
              spacing={atLeast.sm ? 24 : 8}
              direction={atLeast.sm ? 'row' : 'column'}
            >
              <Avatar size='xl' text={userProfile?.name.first?.charAt(0) || ''} />
              <FlexLayout direction='column' spacing={4}>
                <Heading size='h3' color='primary'>
                  {`Good ${greeting}`}, <Callout>{userProfile?.name.first}</Callout>
                </Heading>
                <Body size='small' color='secondary' className='fs-mask'>
                  {userProfile?.email}
                </Body>
              </FlexLayout>
            </FlexLayout>
            <FlexLayout className='hidden flex-col @sm:flex @sm:flex-row' alignItems='center' spacing={16}>
              {isFeatureEnabled(AppFeature.DynamicPriceAlerts) && <PriceAlertButton />}
              {isFeatureEnabled(AppFeature.QuickBuy) && (
                <Button
                  key='quick-buy'
                  size='lg'
                  leadingIcon={<QuickBuy />}
                  onClick={() => navigate(NavigationURLs.QuickBuy)}
                  disabled={!isUserOrMemberTradeEnabled()}
                >
                  Quick Buy
                </Button>
              )}
            </FlexLayout>
          </FlexLayout>
        }
        className={cn('@container', className)}
      >
        <PageContent offset data-container={containerName} className='@container'>
          {!children && layout && (
            <FlexLayout
              direction='column'
              spacing={atLeast.md ? 32 : 24}
              className='flex-wrap'
              data-container='dashboard-page'
            >
              <PageBanner />

              {layout.widgetRows.map((row, index) =>
                row.widgets.length > 0 ? (
                  <React.Fragment key={`dashboard_row_${index}`}>
                    {row.widgets.map((element) => React.cloneElement(element, { atLeast }))}
                  </React.Fragment>
                ) : null,
              )}
            </FlexLayout>
          )}
          {children}
        </PageContent>
      </Page>
    );
  },
);

export { DashboardPage };
