import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserStore } from '@shared/store';

import { useEntitySwitch } from '@hooks/Entity/useEntitySwitch';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { BaseReverificationRestrictionBanner } from './BaseReverificationRestrictionBanner';

type Props = {
  condensed?: boolean;
  onAction?: () => void;
};

export const ReverificationLapsedRestrictionBanner: React.FC<Props> = observer(({ condensed, onAction }) => {
  const { navigate } = useNavigateRoute();
  const { t } = useTranslation('reverifications');
  const { isEntity, reverificationWindowDays } = UserStore.useUserStore;
  const { entityAccounts, selectAccount } = useEntitySwitch();

  const navigateAndAction = () => {
    navigate(NavigationURLs.Reverification);
    onAction?.();
  };

  const handleAction = () => {
    if (isEntity()) {
      const selectedAccount = entityAccounts.find((account) => account.uuid === 'USER');
      if (!selectedAccount) return;
      selectAccount(selectedAccount, navigateAndAction);
    } else {
      navigateAndAction();
    }
  };

  return (
    <BaseReverificationRestrictionBanner
      title={t('banners.lapsed.title')}
      body={condensed ? undefined : t('banners.lapsed.description', { days: reverificationWindowDays() })}
      actionLabel={condensed ? undefined : t('banners.lapsed.action')}
      onAction={condensed ? undefined : handleAction}
      theme='warning'
    />
  );
});
