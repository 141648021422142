import React, { useCallback, useMemo } from 'react';

import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { FlexLayoutProps } from '@swyftx/aviary/atoms/Layout/Flex/FlexLayout.styles';
import { ListItem } from '@swyftx/aviary/atoms/List';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { capitalize } from '@utils/formatting';

import { observer } from 'mobx-react-lite';
import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';
import { useRates } from 'src/lib/rates/hooks';

import { MarketSelectAssetListType } from '../../types/Markets.types';

type Props = {
  asset: Asset;
  type: MarketSelectAssetListType;
  selected: boolean;
  onClick: (asset: Asset) => void;
  disabled?: boolean;
  restricted?: boolean;
};

const SelectAssetListItem: React.FC<Props> = observer(({ asset, type, selected, disabled, restricted, onClick }) => {
  const { convertRate, getRate } = useRates();
  const countryAsset = useCountryAsset();
  const baseAsset = useBaseAsset();
  const { getBalance } = usePortfolioBalance();
  const isXs = useTailwindBreakpoint('xs');

  const rate = useMemo(() => getRate(asset), [asset, getRate]);

  const price = useMemo(() => {
    if (!baseAsset || !countryAsset) return Big(0);

    return convertRate(baseAsset, countryAsset, rate.bidPrice, 'bidPrice');
  }, [baseAsset, convertRate, countryAsset, rate.bidPrice]);

  const isDisabled = useMemo(() => {
    if (disabled) return true;
    if (type === 'buy' && asset.buyDisabled) return true;
    if (type === 'sell' && !asset.sellEnabled) return true;
    if (type === 'withdraw' && !asset.withdraw_enabled) return true;
    if (type === 'deposit' && !asset.deposit_enabled) return true;

    return false;
  }, [asset.buyDisabled, asset.deposit_enabled, asset.sellEnabled, asset.withdraw_enabled, disabled, type]);

  const isDisabledOrRestricted = useMemo(() => isDisabled || !!restricted, [isDisabled, restricted]);

  const isTradeType = useMemo(() => ['buy', 'sell'].includes(type), [type]);

  const holdings = useMemo(() => {
    const balance = getBalance(asset.id);

    if (!balance || !countryAsset || Big(balance.availableBalance).lte(0)) return null;

    return formatCurrency(convertRate(asset, countryAsset, balance.availableBalance, 'midPrice'), countryAsset, {
      appendCode: true,
      hideCode: false,
    });
  }, [getBalance, asset, countryAsset, convertRate]);

  const handleOnClick = useCallback(() => {
    if (isDisabledOrRestricted) return;
    if (type !== 'multi' && selected) return;

    onClick(asset);
  }, [asset, isDisabledOrRestricted, onClick, selected, type]);

  const layoutDirection = useMemo((): FlexLayoutProps['direction'] => {
    if (type === 'buy' || type === 'sell') {
      return holdings ? 'column' : 'row';
    }

    return 'column';
  }, [holdings, type]);

  return (
    <ListItem
      className={cn(
        'mt-8 min-h-[2.5rem] w-full',
        selected && type !== 'multi'
          ? 'bg-color-background-surface-selected hover:bg-color-background-surface-selected'
          : '',
        isDisabledOrRestricted ? 'pointer-events-none cursor-default' : '',
      )}
      onClick={handleOnClick}
    >
      <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
        <FlexLayout alignItems='center' spacing={12} className={cn('w-full')}>
          <AssetIcon asset={asset} size={20} className={isDisabledOrRestricted ? 'opacity-50' : ''} />
          <FlexLayout className='w-full' direction={layoutDirection}>
            <FlexLayout alignItems='center' justifyContent='space-between'>
              <FlexLayout alignItems='center' spacing={8} className={isDisabledOrRestricted ? 'opacity-50' : ''}>
                <Body color='primary' weight='emphasis' className='hidden max-w-[150px] truncate text-left sm:block'>
                  {asset.name}
                </Body>
                <Body
                  size='small'
                  className={cn('text-color-text-primary sm:text-color-text-secondary', 'font-500 sm:font-400')}
                >
                  {asset.code}
                </Body>
              </FlexLayout>

              {holdings && !isDisabled && isTradeType && (
                <Numeric color='primary' weight='emphasis' className='!text-14 sm:!text-16'>
                  {formatCurrency(price, countryAsset)}
                </Numeric>
              )}

              {(type === 'deposit' || type === 'withdraw') && (
                <FlexLayout alignItems='center' justifyContent='end' className='w-full' spacing={12}>
                  {isDisabled && (
                    <Chip size='sm' color='destructive'>
                      <Body size='small'>
                        {capitalize(type === 'deposit' ? 'deposits' : 'withdrawals')} not supported
                      </Body>
                    </Chip>
                  )}
                  {!isDisabled && holdings && (
                    <FlexLayout direction='column'>
                      <Numeric color='primary' weight='bold' className='text-right'>
                        {formatCurrency(getBalance(asset.id)?.availableBalance, asset, {
                          hideCode: false,
                          appendCode: true,
                        })}
                      </Numeric>
                      <Numeric color='secondary' size='small' className='text-right'>
                        {holdings}
                      </Numeric>
                    </FlexLayout>
                  )}
                  {!isDisabled && restricted && (
                    <Chip variant='subtle' size='sm' color='destructive'>
                      <Body size='small'>Restricted</Body>
                    </Chip>
                  )}
                </FlexLayout>
              )}
            </FlexLayout>

            {isTradeType && (
              <FlexLayout
                className={cn(
                  holdings ? 'flex-row items-center' : 'flex-col items-end',
                  'w-full',
                  type === 'priceAlert' ? 'hidden' : '',
                )}
                justifyContent='space-between'
              >
                <FlexLayout alignItems='center' spacing={4} className=''>
                  {holdings && (
                    <>
                      <Numeric color='secondary' size='xsmall'>
                        Holdings:
                      </Numeric>
                      <Numeric color='secondary' size='xsmall'>
                        {holdings}
                      </Numeric>
                    </>
                  )}
                  {!holdings && !isDisabled && (
                    <Numeric color='primary' weight='emphasis'>
                      {formatCurrency(price, countryAsset)}
                    </Numeric>
                  )}
                </FlexLayout>

                {!isDisabled && (
                  <NumericDataItem size={isXs ? 'small' : 'medium'} data={`${rate.dailyPriceChange}%`}>
                    {Number(rate.dailyPriceChange).toFixed(2)}%
                  </NumericDataItem>
                )}
              </FlexLayout>
            )}
          </FlexLayout>
          {type === 'multi' && <Checkbox checked={selected} disabled={isDisabled} />}
        </FlexLayout>
        {isDisabled && isTradeType && (
          <Chip className='capitalize' color='destructive'>
            {type} disabled
          </Chip>
        )}
      </FlexLayout>
    </ListItem>
  );
});

export { SelectAssetListItem };
