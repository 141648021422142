import React from 'react';
import { useTranslation } from 'react-i18next';

import { useIntercom } from 'react-use-intercom';

import { BaseReverificationRestrictionBanner } from './BaseReverificationRestrictionBanner';

type Props = {
  condensed?: boolean;
  onAction?: () => void;
};

export const ReverificationFailedRestrictionBanner: React.FC<Props> = ({ condensed, onAction }) => {
  const { t } = useTranslation('reverifications');
  const { show } = useIntercom();

  const handleAction = () => {
    show();
    onAction?.();
  };

  return (
    <BaseReverificationRestrictionBanner
      title={t('banners.failed.title')}
      body={condensed ? undefined : t('banners.failed.description')}
      actionLabel={condensed ? undefined : t('banners.failed.action')}
      onAction={condensed ? undefined : handleAction}
      theme='error'
    />
  );
};
