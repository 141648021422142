import React, { useMemo } from 'react';

import { CardProps } from '@mui/material';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { cn } from '@shared/utils/lib/ui';

export type ContentBannerProps = {
  actionLabel?: string;
  description: string | React.ReactElement;
  subtitle?: React.ReactElement;
  title: string;
  onAction?: () => void;
  cardSx?: CardProps['sx'];
  subActionLabel?: string;
  icon?: JSX.Element;
  onSubAction?: () => void;
  theme?: 'warning' | 'error';
};

export const ContentBanner: React.FC<ContentBannerProps> = ({
  subActionLabel,
  actionLabel,
  onSubAction,
  onAction,
  description,
  title,
  subtitle,
  icon,
  theme,
}) => {
  const classes = useMemo(() => {
    switch (theme) {
      case 'warning':
        return 'bg-color-background-warning-subtle border-color-border-warning';
      case 'error':
        return 'bg-color-background-error-subtle border-color-border-error';
      default:
        return null;
    }
  }, [theme]);

  return (
    <Card className={cn('w-full p-24', classes)}>
      <FlexLayout spacing={8} justifyContent='space-between'>
        <FlexLayout alignItems='start' spacing={24} className='w-full items-center'>
          {Boolean(icon) && <div>{icon}</div>}

          <FlexLayout
            className='h-full w-full flex-col items-start @sm:flex-row @sm:items-center'
            spacing={8}
            justifyContent='space-between'
          >
            <FlexLayout direction='column' alignItems='start' spacing={subtitle ? 4 : 8}>
              <Heading size='h5'>{title}</Heading>

              {subtitle}
              {typeof description === 'string' && (
                <Body color='secondary' size='small'>
                  {description}
                </Body>
              )}
              {typeof description !== 'string' && description}
            </FlexLayout>
            <FlexLayout spacing={8}>
              {Boolean(onSubAction && subActionLabel) && (
                <Button variant='outlined' color='primary' onClick={onSubAction}>
                  {subActionLabel}
                </Button>
              )}
              {onAction && actionLabel && (
                <Button variant='filled' onClick={onAction} color='primary'>
                  {actionLabel}
                </Button>
              )}
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
};
