import React, { useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { DangerFilled, ErrorFilled } from '@swyftx/aviary/icons/filled';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  title: string;
  body?: string;
  theme: 'warning' | 'error';
  actionLabel?: string;
  onAction?: () => void;
};

export const BaseReverificationRestrictionBanner: React.FC<Props> = ({ title, body, theme, actionLabel, onAction }) => {
  const condensed = body === undefined;

  const classes = useMemo(() => {
    switch (theme) {
      case 'warning':
        return 'bg-color-background-warning-subtle border-color-border-warning';
      case 'error':
        return 'bg-color-background-error-subtle border-color-border-error';
    }
  }, [theme]);

  const icon = useMemo(() => {
    switch (theme) {
      case 'warning':
        return <DangerFilled className='min-w-[24px] text-color-icon-warning' />;
      case 'error':
        return <ErrorFilled className='min-w-[24px] text-color-icon-error' />;
    }
  }, [theme]);

  return (
    <Card className={cn('p-12', classes)}>
      <FlexLayout direction='row' alignItems={condensed ? 'center' : 'start'} spacing={8}>
        {Boolean(icon) && <div>{icon}</div>}
        {condensed ? (
          <Heading size='h5'>{title}</Heading>
        ) : (
          <FlexLayout direction='column' alignItems='start' spacing={12}>
            <Heading size='h5'>{title}</Heading>
            <Body>{body}</Body>
            {actionLabel && onAction && (
              <Button size='sm' variant='filled' color='primary' bodyProps={{ size: 'small' }} onClick={onAction}>
                {actionLabel}
              </Button>
            )}
          </FlexLayout>
        )}
      </FlexLayout>
    </Card>
  );
};
