import React, { useCallback, useContext } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { ReverificationRestrictionBanner } from '@global-components/Banners';

import { DEFAULT_PRICE_SCALE } from '@shared/constants';
import { Big } from '@shared/safe-big';
import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { QuickBuyPresetAmountValueType } from 'src/context/Avo/generated-avo';
import { QuickBuyContext } from 'src/lib/trade/context';
import { useTradeValue } from 'src/lib/trade/hooks/General';
import { useQuickBuyAnalytics } from 'src/lib/trade/hooks/QuickBuy/useQuickBuyAnalytics';
import { useQuickBuyError } from 'src/lib/trade/hooks/QuickBuy/useQuickBuyError';
import { useQuickBuyValues } from 'src/lib/trade/hooks/QuickBuy/useQuickBuyValues';
import { QuickBuyType } from 'src/lib/trade/types/Trade.types';
import { useProfileStatus } from 'src/lib/verification/hooks/useProfileStatus';

import { QuickBuyWidgetTabs, QuickBuyInput, QuickBuyPayPanel } from '../../components';
import { QuickBuyBalance } from '../../components/QuickBuyBalance/QuickBuyBalance';

const QuickBuyWidgetValues: React.FC = observer(() => {
  const {
    amount,
    presetTabs,
    countryAsset,
    selectedAsset,
    limitAsset,
    nonLimitAsset,
    countryBalance,
    selectedAssetBalance,
    nonLimitAmount,
    buyType,
    selectedPaymentMethod,
    allowAssetChange,
    allowPaymentChange,
    tabValue,
    setBuyType,
    setCurrentStep,
    setAmount,
    swapLimitAsset,
  } = useQuickBuyValues();
  const { setTabValue } = useContext(QuickBuyContext);
  const { trackSwitchedTradeDirection, trackPreselectedAmountClicked, trackReviewTradeClicked } =
    useQuickBuyAnalytics();
  const { getTradeError, isValidTrade } = useQuickBuyError();
  const { isVerified } = useProfileStatus();
  const { isUserOrMemberTradeEnabled } = UserStore.useUserStore;

  const { parseTradeValue } = useTradeValue({ limitAsset });

  const updatePrefillValue = useCallback(
    (value: string) => {
      if (buyType === 'buy') {
        trackPreselectedAmountClicked({
          amount: value as QuickBuyPresetAmountValueType,
          asset: selectedAsset!,
          limitAsset: limitAsset!,
          direction: buyType,
        });
        const newValue = parseTradeValue(value, true);
        if (newValue !== undefined) setAmount(newValue);
      } else if (buyType === 'sell' && selectedAsset) {
        const scale = limitAsset?.price_scale ?? DEFAULT_PRICE_SCALE;

        const percentValue = Big(Big(selectedAssetBalance?.availableBalance))
          .times(value)
          .round(scale, 0)
          .toFixed(scale);

        trackPreselectedAmountClicked({
          amount: `${Big(value).times(100).toString()}%` as QuickBuyPresetAmountValueType,
          asset: selectedAsset!,
          limitAsset: limitAsset!,
          direction: buyType,
        });

        if (percentValue !== undefined) setAmount(percentValue);
      }
      setTabValue({ value: value, assetCode: limitAsset?.code ?? '' }, { value: '', assetCode: '' });
    },
    [
      buyType,
      limitAsset,
      parseTradeValue,
      selectedAsset,
      selectedAssetBalance?.availableBalance,
      setAmount,
      setTabValue,
      trackPreselectedAmountClicked,
    ],
  );

  const onChangeBuyType = useCallback(
    (value: QuickBuyType) => {
      setBuyType(value);
      trackSwitchedTradeDirection({ direction: buyType, asset: selectedAsset!, limitedAsset: limitAsset! });

      if (!selectedAsset || !limitAsset || !countryAsset) return;

      if ((value === 'sell' && limitAsset === countryAsset) || (value === 'buy' && selectedAsset === limitAsset)) {
        swapLimitAsset();
      }
    },
    [buyType, countryAsset, limitAsset, selectedAsset, setBuyType, swapLimitAsset, trackSwitchedTradeDirection],
  );

  const handleOnReview = useCallback(() => {
    trackReviewTradeClicked({ asset: selectedAsset!, limitAsset: limitAsset!, amount: amount, direction: buyType });
    setCurrentStep('review');
  }, [amount, buyType, limitAsset, selectedAsset, setCurrentStep, trackReviewTradeClicked]);

  return (
    <FlexLayout direction='column' alignItems='center' justifyContent='space-between' className='h-full w-full'>
      <QuickBuyWidgetTabs value={buyType} onChange={onChangeBuyType} />
      <FlexLayout direction='column' className='h-full w-full p-24' alignItems='center' justifyContent='space-between'>
        <FlexLayout
          direction='column'
          className='h-full w-full'
          spacing={24}
          alignItems='center'
          justifyContent='space-between'
        >
          <ReverificationRestrictionBanner />
          {nonLimitAsset && limitAsset && selectedAsset && (
            <FlexLayout direction='column' spacing={8} className='items-center justify-center text-center'>
              <QuickBuyInput
                nonLimitAsset={nonLimitAsset}
                limitAsset={limitAsset}
                selectedAsset={selectedAsset}
                swapLimitAsset={() => {
                  swapLimitAsset();
                }}
                amount={amount}
                nonLimitAmount={nonLimitAmount}
                setAmount={setAmount}
                error={!isValidTrade}
              />
              <QuickBuyBalance
                error={getTradeError()}
                setTabValue={setTabValue}
                setAmount={setAmount}
                setCurrentStep={setCurrentStep}
                buyType={buyType}
                limitAsset={limitAsset}
                countryAsset={countryAsset}
                countryBalance={countryBalance}
                selectedAsset={selectedAsset}
                isVerified={isVerified}
                selectedAssetBalance={selectedAssetBalance}
              />
            </FlexLayout>
          )}
          {(!nonLimitAsset || !limitAsset || !selectedAsset) && (
            <>
              <div className='h-[156px]' />
              <div className='h-[24px]' />
            </>
          )}

          <div className='w-full'>
            <EnhancedTabs tabs={presetTabs} value={tabValue.current.value} onChange={updatePrefillValue} size='md' />
          </div>

          <QuickBuyPayPanel
            selectedAsset={selectedAsset}
            selectedPaymentMethod={selectedPaymentMethod}
            setStep={setCurrentStep}
            buyType={buyType}
            allowAssetChange={allowAssetChange}
            allowPaymentChange={allowPaymentChange}
          />
          <Button
            size='lg'
            className='w-full'
            onClick={handleOnReview}
            disabled={!isUserOrMemberTradeEnabled() || !isValidTrade || Big(amount).eq(0) || !isVerified}
          >
            Review order
          </Button>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

export { QuickBuyWidgetValues };
