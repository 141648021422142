import React, { useContext, useMemo } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { observer } from 'mobx-react-lite';

import {
  QuickBuyWidgetAssetPicker,
  QuickBuyWidgetValues,
  QuickBuyWidgetPaymentMethod,
  QuickBuyWidgetReview,
} from './steps';
import { QuickBuyWidgetComplete } from './steps/QuickBuyWidgetComplete/QuickBuyWidgetComplete';
import { QuickBuyWidgetFailure } from './steps/QuickBuyWidgetFailure/QuickBuyWidgetFailure';
import { QuickBuyContext, QuickBuyContextProvider } from '../../context';
import { QuickBuyType } from '../../types/Trade.types';

type Props = {
  assetCode: string;
  tradeSide?: QuickBuyType;
  allowAssetChange?: boolean;
  allowPaymentChange?: boolean;
};

const QuickBuyWidgetContent: React.FC = () => {
  const { currentStep } = useContext(QuickBuyContext);

  const content = useMemo(() => {
    switch (currentStep) {
      case 'values':
        return <QuickBuyWidgetValues />;
      case 'paymentMethod':
        return <QuickBuyWidgetPaymentMethod />;
      case 'assetPicker':
        return <QuickBuyWidgetAssetPicker />;
      case 'review':
        return <QuickBuyWidgetReview />;
      case 'complete':
        return <QuickBuyWidgetComplete />;
      case 'failure':
        return <QuickBuyWidgetFailure />;
    }
  }, [currentStep]);

  return (
    <Card
      className='
        min-h-[36rem] min-w-full overflow-hidden
        rounded-24 sm:min-h-[35.5rem] sm:min-w-[28rem] sm:max-w-[28rem]'
    >
      {content}
    </Card>
  );
};

const QuickBuyWidget: React.FC<Props> = observer(({ assetCode, allowAssetChange, allowPaymentChange, tradeSide }) => {
  const countryAsset = useCountryAsset();

  if (!countryAsset) return null;

  return (
    <QuickBuyContextProvider
      countryAsset={countryAsset}
      assetCode={assetCode}
      tradeSide={tradeSide}
      allowAssetChange={allowAssetChange}
      allowPaymentChange={allowPaymentChange}
    >
      <QuickBuyWidgetContent />
    </QuickBuyContextProvider>
  );
});

QuickBuyWidget.displayName = 'QuickBuyWidget';

export { QuickBuyWidget };
