import { useContext, useEffect, useState } from 'react';

import { balanceService, userStatisticsService } from '@shared/services';
import entityService from '@shared/services/entityService';
import storage, { StorageKey } from '@shared/storage';
import { UIStore, UserStore } from '@shared/store';
import { EntityAccount } from '@shared/store/userStore/@types/userTypes';

import { LoginContext } from '@routes/Login';

const useEntitySwitch = () => {
  const [selectedAccount, setSelectedAccount] = useState('USER');
  const [switchingAccount, setSwitchingAccount] = useState<string>('');
  const [switchingAccountError, setSwitchingAccountError] = useState<string>('');
  const { initThirdParty } = useContext(LoginContext);
  const { setNavDrawerExpanded } = UIStore.useUIStore;
  const { entityAccounts } = UserStore.useUserStore;

  useEffect(() => {
    const updateSelectedAccount = async () => {
      const account = await storage.getItem(StorageKey.CURRENT_ACCOUNT);
      if (account) setSelectedAccount(account);
    };
    entityService.updateEntities();
    updateSelectedAccount();
  }, []);

  const selectAccount = (entity: EntityAccount, onSwitch?: () => void) => {
    setSwitchingAccount(entity.uuid);
    setSwitchingAccountError('');
    entityService.switchAccounts(
      entity.uuid,
      entity.entityColorIndex,
      () => {
        entityService.updateMembers();
        setSelectedAccount(entity.uuid);
        setNavDrawerExpanded(false);
        setSwitchingAccount('');

        userStatisticsService.forceUpdate();
        balanceService.forceUpdate();

        onSwitch?.();
      },
      initThirdParty,
      () => {
        setSwitchingAccountError(entity.uuid);
      },
    );
  };

  return { selectedAccount, switchingAccount, switchingAccountError, selectAccount, entityAccounts };
};

export { useEntitySwitch };
