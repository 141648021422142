import React from 'react';

import { ReverificationStatusEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

import { ReverificationFailedRestrictionBanner } from './ReverificationFailedRestrictionBanner';
import { ReverificationLapsedRestrictionBanner } from './ReverificationLapsedRestrictionBanner';

type Props = {
  condensed?: boolean;
  onOpenReverification?: () => void;
  onOpenIntercom?: () => void;
};

export const ReverificationRestrictionBanner: React.FC<Props> = observer(
  ({ condensed, onOpenReverification, onOpenIntercom }) => {
    const { userProfile, previousUserProfile } = UserStore.useUserStore;

    const reverificationStatus =
      userProfile?.accountStatus?.reverification?.status ??
      previousUserProfile?.accountStatus?.reverification?.status ??
      ReverificationStatusEnum.VERIFIED;

    if (reverificationStatus === ReverificationStatusEnum.LAPSED) {
      return <ReverificationLapsedRestrictionBanner condensed={condensed} onAction={onOpenReverification} />;
    }

    if (reverificationStatus === ReverificationStatusEnum.FAILED) {
      return <ReverificationFailedRestrictionBanner condensed={condensed} onAction={onOpenIntercom} />;
    }

    return null;
  },
);
