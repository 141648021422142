import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const ErrorFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.8692 2.49707C11.4916 1.87467 12.5007 1.87467 13.123 2.49707L21.4942 10.8684C22.1166 11.4908 22.1166 12.4999 21.4942 13.1223L13.123 21.4937C12.5007 22.1161 11.4916 22.1161 10.8692 21.4937L2.49804 13.1223C1.87565 12.4999 1.87565 11.4908 2.49804 10.8684L10.8692 2.49707ZM11.9863 8.24512C12.4006 8.24512 12.7363 8.5809 12.7363 8.99512V12.0951C12.7363 12.5093 12.4006 12.8451 11.9863 12.8451C11.5721 12.8451 11.2363 12.5093 11.2363 12.0951V8.99512C11.2363 8.5809 11.5721 8.24512 11.9863 8.24512ZM11.9961 15.7451C12.4103 15.7451 12.7461 15.4093 12.7461 14.9951C12.7461 14.5809 12.4103 14.2451 11.9961 14.2451C11.5819 14.2451 11.2461 14.5809 11.2461 14.9951C11.2461 15.4093 11.5819 15.7451 11.9961 15.7451Z'
      fill='currentColor'
    />
  </svg>
);

export { ErrorFilled };
