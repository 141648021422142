import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Stack, Typography } from '@swyftx/react-web-design-system';

import { HighSlippageReviewModal } from '@global-components/Modals/HighSlippageReviewModal/HighSlippageReviewModal';

import { Big } from '@shared/safe-big';
import { UniversalTradeStore, UserStore } from '@shared/store';
import { TradeState, TradeType } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useAvo } from '@hooks/Avo/useAvo';
import { useUniversalTradeAsset, useUniversalTradeUtilityStore } from '@hooks/Trade';
import { getAssetBreakdowns } from '@utils/analytics';

import { observer } from 'mobx-react-lite';

type Props = {
  tradeDataKey: string;
};

const TradeOrderDetailsSummary: React.FC<Props> = observer(({ tradeDataKey }) => {
  const [reviewHighSlippage, setReviewHighSlippage] = useState<boolean>(false);
  const { setTradeState, tradeType, tradeFrom, tradeTo } = UniversalTradeStore;
  const { isUserOrMemberTradeEnabled } = UserStore.useUserStore;
  const {
    fromAsset,
    toAsset,
    percentage,
    limitAsset,
    balance,
    getTrigger,
    isHighSlippage,
    amount,
    total,
    trigger,
    interactedWith,
    customTrigger,
    clientSideError,
    isOverLowLiquidityThreshold,
    error,
    loading,
  } = useUniversalTradeAsset(tradeDataKey);
  const { getDisplayExchangeRate } = useUniversalTradeUtilityStore();
  const { t } = useTranslation('trade', { keyPrefix: 'tradeOrderDetails' });

  const { tradeData } = UniversalTradeStore;
  const { resetTradeAssets } = useUniversalTradeUtilityStore();

  const displayRates = getDisplayExchangeRate();

  const { pathname } = useLocation();
  const avo = useAvo();

  const disabledBuy = useMemo(
    () =>
      !fromAsset ||
      !toAsset ||
      Big(balance).lte(0) ||
      !getTrigger() ||
      !trigger ||
      Big(amount).lte(0) ||
      Big(total).lte(0) ||
      (tradeType === TradeType.OnTrigger && !customTrigger) ||
      loading ||
      (clientSideError !== undefined && clientSideError.length > 0) ||
      (error !== undefined && error.length > 0) ||
      isOverLowLiquidityThreshold ||
      customTrigger === '0' ||
      customTrigger === '.' ||
      !isUserOrMemberTradeEnabled(),

    [
      isOverLowLiquidityThreshold,
      fromAsset,
      toAsset,
      balance,
      getTrigger,
      tradeType,
      customTrigger,
      loading,
      clientSideError,
      error,
      trigger,
      amount,
      total,
      isUserOrMemberTradeEnabled,
    ],
  );

  const reviewDisabledTitle = useMemo(() => {
    if (clientSideError || error || isOverLowLiquidityThreshold) return t('labels.fixTradeErrors');
    if (loading) return t('labels.fetchingRates');

    return '';
  }, [clientSideError, error, isOverLowLiquidityThreshold, loading, t]);

  const handleOrderDetailsReviewed = () => {
    setTradeState(TradeState.OrderSummary);

    // Fire event for trade analytics: order details reviewed (single asset btn)
    avo.orderDetailsReviewed({
      ...getAssetBreakdowns(tradeData),
      tradeType,
      ...(customTrigger ? { trigger: parseFloat(customTrigger) } : {}),
      limitAssetCode: limitAsset?.code ?? '',
      fromAssetPercentage: percentage,
      sliderUsed: interactedWith === 'slider',
      sliderInputUsed: interactedWith === 'sliderInput',
      quantityFieldUsed: interactedWith === 'input',
      screen: pathname,
      layout: '',
    });
  };

  const onReview = () => {
    if (isHighSlippage) {
      setReviewHighSlippage(true);
      return;
    }

    handleOrderDetailsReviewed();
  };

  return (
    <>
      <FlexLayout direction='column' spacing={24} className='p-24'>
        {!!displayRates.length && (
          <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
            <Stack spacing={1} direction='row' alignItems='center'>
              <Typography color='text.primary' fontWeight={600} fontSize={14}>
                {tradeType === TradeType.Instantly ? t('labels.price') : t('labels.priceTrigger')}
              </Typography>
            </Stack>
            <Stack direction='column' alignItems='flex-end'>
              {displayRates && (
                <Typography color='text.primary' fontWeight={500} fontSize={16} number>
                  {displayRates[0]}
                </Typography>
              )}
            </Stack>
          </Stack>
        )}

        <FlexLayout direction='row' className='full-width' spacing={8}>
          <Button
            variant='outlined'
            size='lg'
            onClick={resetTradeAssets}
            disabled={!tradeFrom.length && !tradeTo.length}
            className='w-[100%]'
          >
            {t('buttonLabels.clear')}
          </Button>
          <Tooltip title={reviewDisabledTitle} side='top'>
            <Button size='lg' loading={loading} disabled={disabledBuy} onClick={onReview} className='w-[100%]'>
              {t('buttonLabels.review')}
            </Button>
          </Tooltip>
        </FlexLayout>
      </FlexLayout>
      {reviewHighSlippage && (
        <HighSlippageReviewModal onClose={() => setReviewHighSlippage(false)} onContinue={handleOrderDetailsReviewed} />
      )}
    </>
  );
});

TradeOrderDetailsSummary.displayName = 'TradeOrderDetailsSummary';

export { TradeOrderDetailsSummary };
